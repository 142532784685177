import React, { Fragment } from "react"
import styled from "styled-components"
import DetailsButton from "../buttons/details"
import "./style.scss"
//
const StyledButton = styled(DetailsButton)`
  margin-top: 2em;
`
//
function BenefitsItem(props) {
  return (
    <Fragment>
      <div className="benefits_item">
        <div className="benefits_item_title">{props.title}</div>
        <div className="benefits_item_desc">{props.desc}</div>
        <StyledButton link={props.linkButton}>Подробнее</StyledButton>
      </div>
    </Fragment>
  )
}

export default BenefitsItem
