import React from "react"
import DetailsButton from "../../buttons/details"
import styled from "styled-components"
import "./style.scss"

const Wrapper = styled.div`
  color: #1d4d7a;
  margin: 2em 0;
`

const Block = styled.div`
  display: flex;
  justify-content: space-between;
`
const Title = styled.div`
  font-size: 2rem;
  text-align: ${props => (props.align === "right" ? "left" : "right")};
`

const WrapSVG = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  @media screen and (max-width: 540px) {
    display: none;
  }
`

const Desc = styled.div`
  max-width: 60%;
  display: inline-block;
  margin: 2em 0;
  text-align: ${props => (props.align === "right" ? "right" : "left")};
  @media screen and (max-width: 540px) {
    max-width: 100%;
    text-align: left;
  }
`

const DescSec = styled.div`
  padding: 2em 0;
  text-align: ${props => (props.align === "right" ? "right" : "left")};
  position: relative;
  @media screen and (max-width: 540px) {
    text-align: left;
  }
  &::before {
    content: "";
    position: absolute;
    border: none;
    border-top: 1px solid #1d4d7a;
    top: 0;
    ${props => (props.align === "right" ? "right: 0" : "left: 0")};
    width: 100%;
    max-width: 15em;
  }
`
//
function Product({ children, title, desc, descSec, icon, align, linkButton }) {
  const RightBlock = () => {
    return (
      <>
        <Title align="right">{title}</Title>
        <Block>
          <Desc align="left">{desc}</Desc>
          <WrapSVG>{icon}</WrapSVG>
        </Block>
        {descSec && <DescSec align="left">{descSec}</DescSec>}
        <DetailsButton align="left" link={linkButton}>
          Подробнее
        </DetailsButton>
      </>
    )
  }
  const LeftBlock = () => {
    return (
      <>
        <Title align="left">{title}</Title>
        <Block>
          <WrapSVG>{icon}</WrapSVG>
          <Desc align="right">{desc}</Desc>
        </Block>
        {descSec && <DescSec align="right">{descSec}</DescSec>}
        <DetailsButton align="right" link={linkButton}>
          Подробнее
        </DetailsButton>
      </>
    )
  }
  return (
    <Wrapper>
      {align === "right" ? <RightBlock /> : <LeftBlock />}
      {children}
    </Wrapper>
  )
}

export default Product
