import React, { Fragment } from "react"
import BenefitsItem from "src/components/benefits-item/index"
import FormContact from "src/components/form-contact/index"
import Service from "src/components/section/service/index"
import Product from "src/components/section/product/index"
import Container from "src/components/container"
import SEO from "src/components/seo"
import ProductSVG1 from "src/img/svg/product-1.svg"
import ProductSVG2 from "src/img/svg/product-2.svg"
import "src/styles/index.scss"

export default function Home() {
  return (
    <Fragment>
      <SEO
        title="Главная"
        description="Информационная безопасность для вашего бизнеса"
      />
      <Container>
        <section className="intro">
          <div className="container">
            <div className="slogan">
              <h1>Информационная безопасность для вашего бизнеса</h1>
              <h3>без лишних затрат и головной боли</h3>
            </div>
          </div>
        </section>
        <section className="benefits mt-0">
          <div className="container">
            <div className="row w-100 justify-content-between">
              <div className="mt-5 col-12 col-md-5">
                <BenefitsItem
                  title="Тестирование на проникновение"
                  desc="Проверка безопасности вашего сайта, инфраструктуры, мобильного приложения и любой другой IT-системы в условиях реальной хакерской атаки."
                  linkButton="/pentest"
                />
              </div>
              <div className="mt-5 col-12 col-md-5">
                <BenefitsItem
                  title="Разработка безопасного ПО"
                  desc="Закажите у нас разработку приложения или отдельных его узлов с учетом прогноза уязвимостей и ведущих мировых практик по информационной безопасности."
                  linkButton="/develop"
                />
              </div>
            </div>
          </div>
        </section>
        <section className="service">
          <div className="container">
            <Service
              title="Тестирование на проникновение"
              desc="
            Пентест (от англ. «penetration testing»), как услуга, позволяет
            провести аудит безопасности сайта. Иными словами, это тестирование —
            проверка безопасности вашего сайта, инфраструктуры и в целом бизнеса
            в условиях реальной хакерской атаки. Мы воспроизводим действия
            реальных хакеров в рамках тысяч существующих угроз безопасности,
            будь то сайт на Wordpress, Joomla или веб-приложение на Python, GO,
            Java, Ruby, C++, ASP.NET."
              descSec="Мы находим уязвимости в корпоративной инфраструктуре, исходном коде, мобильных приложениях и любой другой IT-системе. После тестов мы предоставляем отчет с конкретными шагами по снижению рисков, помогаем исправить уязвимости, осуществляем мониторинг безопасности сайта при необходимости. А также даем детальные консультации и советы о том как провести аудит безопасности сайта самостоятельно."
              btn
              linkButton="/pentest"
            />
          </div>
        </section>
        <section className="product">
          <div className="container">
            <Product
              title="Тестирование на проникновение"
              desc="Проверка безопасности вашего сайта, инфраструктуры, мобильного приложения и любой другой IT-системы в условиях реальной хакерской атаки."
              icon={<ProductSVG1 />}
              align="right"
              linkButton="/pentest"
            />
          </div>
        </section>
        <section className="service">
          <div className="container">
            <Service
              title="Разработка надёжного ПО"
              desc="Мы — команда back-end и front-end разработчиков, DevOps-инженеров, AppSec и Infosec-специалистов со стажем более 10 лет и страстью к перфекционизму. Создаем надежные решения для достижения ваших целей и защиты вашего бизнеса. Отлаженные процессы и внимание к мельчайшим деталям помогают нам добиваться качества, будь то крупная разработка для корпораций, веб-приложение для локального бизнеса или консультирование по вопросам безопасности."
              descSec="Для нас нет границ: веб-приложения, мобильная разработка или высоконагруженные веб-сервисы на основе Big Data — расскажите о вашей идее, а мы найдем надежный и экономичный способ ее реализации. Разработаем веб-проект любой сложности для крупных компаний, малого и среднего бизнеса, подготовим его к релизу и красиво обернем, чтобы проверить бизнес-идею. При разработке вашего проекта мы учитываем прогноз потенциальных уязвимостей, проводим множественные пентесты и используем ведущие мировые практики в области безопасности данных для защиты от внутренних и внешних атак."
              btn
              linkButton="/develop"
            />
          </div>
        </section>
        <section className="product">
          <div className="container">
            <Product
              title="Разработка безопасного ПО"
              desc="Закажите у нас разработку приложения или отдельных его узлов с учетом прогноза уязвимостей и ведущих мировых практик по информационной безопасности."
              icon={<ProductSVG2 />}
              align="left"
              linkButton="/develop"
            />
          </div>
        </section>
        <FormContact ticketType="main" fieldQuestion="true" />
      </Container>
    </Fragment>
  )
}
